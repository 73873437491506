import Link from 'next/link';

import Logo from './icons/Logo';
import NavMenu from '../components/NavMenu'
import Account from '../components/Account'
import s from './Navbar.module.css'
import Feedback from './Feedback'

const fetcher = (url, token) =>
  fetch(url, {
    method: 'GET',
    headers: new Headers({ 'Content-Type': 'application/json', token }),
    credentials: 'same-origin',
  }).then((res) => res.json())

const Navbar = () => {
  

  return (
    <nav id="nav" className={s.root}>
      <a href="#skip" className="sr-only focus:not-sr-only">
        Skip to content
      </a>
      <div className="mx-auto max-w-6xl px-6">
        <div className="flex justify-between align-center flex-row py-4 md:py-6 relative">
          <div className="flex flex-1 items-center">
            <Link href="/">
              <a className={s.logo} aria-label="Logo">
                <Logo />
              </a>
            </Link>
            <nav className="space-x-2 ml-6 hidden lg:block">
            </nav>
          </div>

          <div className="flex flex-1 justify-end">
            <NavMenu />
            <Account />
            <Feedback />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
