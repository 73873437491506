import React, { ReactNode } from 'react'
import Head from 'next/head'
import Link from 'next/link'
import Navbar from './nav'
import Footer from './footer'
import { loadStripe } from '@stripe/stripe-js'
import { CartProvider } from 'use-shopping-cart'

type Props = {
  children: ReactNode
  title?: string,
  full?: boolean,
  flex?: boolean
}

const stripePromise = loadStripe(process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY)

const Layout = ({
  children,
  title = 'TypeScript Next.js Stripe Example',
  full = false,
  flex = false
}: Props) => (
  <>
    <Head>
      <title>{title}</title>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <link rel="favicon" href="https://static.desica.uk/favicon.ico" />
    </Head>
    <CartProvider cancelUrl="https://landing-git-dev-jacobhq.vercel.app/" currency="GBP" successUrl="https://landing-git-dev-jacobhq.vercel.app/" mode="client-only" stripe={stripePromise}>
      <div className="bg-black">
        <Navbar />
        <div className={`${full ? 'min-h-screen w-full bg-black' : 'bg-black'} ${flex ? 'flex items-center justify-center' : ''}`}>
          {children}
        </div>
        <Footer />
      </div>
    </CartProvider>
  </>
)

export default Layout
